$icomoon-font-family: "icoCostaMKB" !default;
$icomoon-font-path: "/assets/fonts" !default;

$icon-save: "\e900";
$icon-background: "\e901";
$icon-chevron-left: "\e902";
$icon-chevron-right: "\e903";
$icon-editor: "\e904";
$icon-elements: "\e905";
$icon-feedback: "\e906";
$icon-finalizza: "\e907";
$icon-home: "\e908";
$icon-info: "\e909";
$icon-layout: "\e90a";
$icon-light: "\e90b";
$icon-search-item: "\e90c";
$icon-shield: "\e90d";
$icon-ship-check: "\e90e";
$icon-ship: "\e910";
$icon-templates: "\e911";
$icon-arrow-right: "\e912";
$icon-arrow-left: "\e913";
$icon-selected-file: "\e914";
$icon-add-inverse: "\e915";
$icon-edit: "\e916";
$icon-trash: "\e917";
$icon-close: "\e918";
$icon-drag-n-drop: "\e919";
$icon-more: "\e91a";
$icon-edit-alt: "\e91b";
$icon-search: "\e91c";
$icon-calendar-sharp: "\e91d";
$icon-cloud-upload: "\e91e";
$icon-options: "\e91f";
