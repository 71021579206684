/* _input-group-custom.scss */
.input-group-custom {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  &--icon-append {
    .form-control-custom {
      padding: 0.63rem 2.45rem 0.63rem 0.75rem;
      background-position: center right 3rem;
    }
  }
  &--icon-prepend {
    .form-control-custom {
      padding: 0.63rem 0.75rem 0.63rem 2.45rem;
    }
    &--transparent {
      .form-control-custom {
        padding: 0.63rem 0.75rem 0.63rem 2.15rem;
      }
    }
  }

  > .form-control-custom {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
  }

  &__label {
    @extend .form-group-custom__label;
    flex: 1 0 100%;
  }

  &__append,
  &__prepend {
    display: flex;
    background-color: $ui-color-07;
    transition: border-color ease-out 0.22s;
    position: absolute;
    top: 1px;
    bottom: 1px;
    border: none;
    z-index: 1;
    &--transparent {
      background-color: transparent;
      border-right: none;
    }
  }

  &__append {
    right: 1px;
    border-left: 1px solid #ced4da;
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
    &--transparent {
      border-left: none;
    }
  }

  &__prepend {
    left: 1px;
    border-right: 1px solid #ced4da;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
    &--transparent {
      border-right: none;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $text-color-placeholder;
    text-align: center;
    white-space: nowrap;
    min-width: 2.38rem; /* 38px; */
    &--transparent {
      padding: 0.375rem 1rem;
      min-width: 1.9rem;
      color: $brand-color-01;
    }
  }

  &__cleaner {
    color: $text-color-primary;
    opacity: .5;
    transition: opacity .11s;
    &:hover {
      opacity: 1;
      transition: opacity .22s;
    }
  }

}
/* _input-group-custom.scss END */
