.errors {
  display: flex;
  flex-flow: row nowrap;
  &__claim {
    color: red;
    font-weight: 600;
  }
  &__feedback {
    margin-right: 3rem;
    &-list {
      padding-left: 1rem;
      font-size: .875rem;
      list-style: none;
      margin: .5rem 0;
    }
    &-item {

    }
  }
}
