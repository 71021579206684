@import "./colors";
@import "./u-link";
@import "./variables";

$aside-menu: 275px;

:root {
  --shadow-color: 0deg 0% 70%;
  --shadow-elevation-low:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.27),
    0px 0.8px 0.9px -1.2px hsl(var(--shadow-color) / 0.27),
    0px 1.8px 2px -2.5px hsl(var(--shadow-color) / 0.27);
  --shadow-elevation-medium:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.29),
    0px 1.5px 1.7px -0.8px hsl(var(--shadow-color) / 0.29),
    0px 3.8px 4.3px -1.7px hsl(var(--shadow-color) / 0.29),
    0.1px 9.1px 10.2px -2.5px hsl(var(--shadow-color) / 0.29);
  --shadow-elevation-high:
    0px 0.5px 0.6px hsl(var(--shadow-color) / 0.3),
    0px 2.8px 3.1px -0.4px hsl(var(--shadow-color) / 0.3),
    0.1px 5.3px 6px -0.8px hsl(var(--shadow-color) / 0.3),
    0.1px 9.3px 10.5px -1.2px hsl(var(--shadow-color) / 0.3),
    0.2px 15.7px 17.7px -1.7px hsl(var(--shadow-color) / 0.3),
    0.3px 25.8px 29px -2.1px hsl(var(--shadow-color) / 0.3),
    0.5px 40.6px 45.7px -2.5px hsl(var(--shadow-color) / 0.3);
}

body, html {
  height: 100%;
}

body {
  margin: 0;
}

.o-layout {
  width: 100%;
  min-width: 750px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  height: 100%;

  &.loading {
    padding: 0.75rem;
  }
  /*height: calc(100vh - 150px + .5rem);*/
  /*padding: .5rem;*/
  &--builder {
    padding: 0;
    .o-layout {
      /*height: calc(100vh - 230px + .5rem);*/
      height: auto;
    }
  }
  /*@media (min-width: 768px) and (max-width: 991px) {
    max-width: 820px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 940px;
  }
  @media (min-width: 1200px) and (max-width: 1299px) {
    max-width: 960px;
  }
  @media (min-width: 1300px) and (max-width: 1599px) {
    max-width: 1280px;
  }
  @media (min-width: 1600px) {
    max-width: 1440px;
  }*/
  &--vertical {
    display: flex;
    /*flex-flow: column;*/
    @media (min-width: 992px) {
      flex-flow: row nowrap;
    }
  }
  &-aside {
    flex: 1 1 0;
    z-index: 2;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    @media (min-width: 992px) {
      flex: 0 0 20%;
      max-width: $aside-menu;
    }
  }
  &-body {
    flex: 1;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: .5rem 0 0;
    box-sizing: border-box;
    @media (min-width: 992px) {
      flex: 1 0 80%;
      max-width: calc(100% - #{$aside-menu});
    }
    &.full-width {
      flex: 1;
      max-width: 100%;
      padding: 0;
    }
  }
  &-row {
    display: flex;
    flex-wrap: wrap;
    &__col {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
}

.o-section {
  h1 {
    color: $color-blue;
    margin: 1rem 0;
  }
  &__title {
    border-bottom: 1px solid #A9A9A9;
    max-width: 80%;
    padding-bottom: 3rem;
    margin-bottom: 4rem;
  }
  &-admin {
    .o-section__content {
      max-width: 80%;
      padding-bottom: 70px;
      height: 100%;
    }
  }
  &-builder {
    height: 100vh;
  }
}

::ng-deep {
  ngx-smart-modal {
    .nsm-dialog {
      max-width: 80% !important;
    }
    .nsm-content {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, .4);
      border-radius: 8px;
      height: 88vh;
      padding: 1.5rem;
      margin-left: 0;
      margin-right: 0;
      overflow: auto;
      @extend .u-scrollbar;
    }
    .overlay.nsm-overlay-open {
      background-color: rgba(255, 255, 255, .8);
    }
    &[identifier="mkbCreativityModal"], &[identifier="mkbPosterModal"], &[identifier="mkbCruiseDetailModal"] {
      .nsm-dialog {
        min-height: unset;
      }
      .nsm-content {
        height: fit-content;
      }
    }
  }
  .swal2-styled.swal2-confirm {
    font-size: 1rem;
    line-height: 1.3;
    text-decoration: none;
    text-align: center;
    text-shadow: none;
    border-radius: $btn-border-radius !important;
    text-transform: uppercase;
    font-weight: 600;
    color: $brand-color-03 !important;
    background-color: $brand-color-02 !important;
    border: $btn-border-width solid $brand-color-02 !important;

    &:focus,
    &:hover {
      color: $brand-color-03 !important;
      background-color: darken($brand-color-02, 7) !important;
      border-color: darken($brand-color-02, 7) !important;
      transition: background-color .11s ease-in, color .22s ease-in;
      outline: none;
      text-decoration: none;
      box-shadow: none !important;
    }

    &:active {
      text-decoration: none;
    }
  }
  .swal2-styled.swal2-cancel {
    font-size: 1rem;
    line-height: 1.3;
    text-decoration: none;
    text-align: center;
    text-shadow: none;
    border-radius: $btn-border-radius !important;
    text-transform: uppercase;
    font-weight: 600;
    color: $brand-color-03 !important;
    background-color: transparent !important;
    border: $btn-border-width solid $brand-color-03 !important;

    &:focus,
    &:hover {
      text-decoration: none;
      box-shadow: none !important;
      color: darken($brand-color-03, 7) !important;
      border-color: darken($brand-color-03, 7) !important;
      transition: border-color .11s ease-in, color .22s ease-in !important;
      outline: none;
    }

    &:active {
      text-decoration: none;
    }
  }
}

.u-scrollbar {
  //Firefox
  scrollbar-color: #ccc;
  scrollbar-width: thin; //thin;
  transition: scrollbar-color .33s ease-out;

  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5em;
    transition: background .22s ease-in;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    transition: background .22s ease-in, scrollbar-color .22s ease-in;
  }
  &:hover {
    scrollbar-width: thin;
  }
}
