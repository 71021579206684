.u-list-tags, .u-list-targets {
  list-style: none;
  padding-left: 0;
  margin-top: .6rem;
  &__item {
    border: 1px solid #A9A9A9;
    background-color: #fff;
    padding: .5rem .75rem;
    margin-bottom: 1rem;
    display: flex;
    &.invalid {
      box-shadow: 0 0 4px 0px red;
      border: 1px solid red;
    }
    &[disabled], &.disabled {
      opacity: .6;
    }
  }
  &__label {
    flex: 1 0 70%;
    align-self: center;
  }
  &__recap {
    flex: 1 0 20%;
    align-self: center;
    font-size: .8125rem;
  }
  &__icon {
    flex: 0 0 7%;
    text-align: center;
    align-self: center;
    i {
      display: inline-block;
      cursor: pointer;
    }
    .icon-drag-n-drop {
      margin-left: 1rem;
      font-size: 1.2rem;
      vertical-align: middle;
    }
  }
  &__stamp {
    span {
      height: 18px;
      width: 18px;
      margin-top: 0.58rem;
      border-radius: 100%;
      background-color: #F9B000;
      display: block;
    }
    i {
      margin-top: .4rem;
      display: block;
      color: $brand-color-01;
      font-size: 1.2rem;
    }
    &.active {
      span {
        background-color: #64B65C;
      }
    }
    &.pending {
      span {
        background-color: #F9B000;
      }
    }
    &.disabled {
      span {
        background-color: #FC726C;
      }
    }
  }
}
