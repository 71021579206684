.u-link {
  cursor: pointer;
  &--sm {
    font-size: .8125rem;
  }
  &--xs {
    font-size: .685rem;
  }
  &--underline {
    text-decoration: underline;
  }
  &--color-primary {
    color: $color-blue;
  }
  &--white {
    color: white;
  }
  &--bold {
    font-family: 'Poppins-Bold', sans-serif;
  }
  &--semi-bold {
    font-family: 'Poppins-Medium', sans-serif;
  }
}
