@font-face {
   font-family: 'Poppins';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Regular.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Regular.woff) format('woff'), /* Pretty Modern Browsers */
}

@font-face {
   font-family: 'Poppins-ExtraLight';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-ExtraLight.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-ExtraLight.woff) format('woff'), /* Pretty Modern Browsers */
}

@font-face {
   font-family: 'Poppins-Light';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Light.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Light.woff) format('woff'), /* Pretty Modern Browsers */
}

@font-face {
   font-family: 'Poppins-Medium';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Medium.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Medium.woff) format('woff'), /* Pretty Modern Browsers */
}

@font-face {
   font-family: 'Poppins-SemiBold';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-SemiBold.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-SemiBold.woff) format('woff'), /* Pretty Modern Browsers */
}

@font-face {
   font-family: 'Poppins-Bold';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Bold.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Bold.woff) format('woff'), /* Pretty Modern Browsers */
}


@font-face {
   font-family: 'Poppins-ExtraBold';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-ExtraBold.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-ExtraBold.woff) format('woff'), /* Pretty Modern Browsers */
}

@font-face {
   font-family: 'Poppins-Black';
   src: url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Black.ttf) format('embedded-opentype'), /* Internet Explorer */
   url(https://www.offertetouroperator.com/resources/marketing-box/assets/fonts/Poppins-Black.woff) format('woff'), /* Pretty Modern Browsers */
}


body {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

h1 {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: 2.188rem; //35px
}

h2 {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: 1.625rem; //26px
}

h3 {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: 1.25rem; //20px
}

h4 {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: 1.125rem; //18px
}

h5 {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: 1rem; //16px
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem; //16px
  margin: 0 0 .5rem;
  letter-spacing: 0;
}

small {
  font-family: 'Poppins', sans-serif;
  font-size: 0.813rem; //13px
  letter-spacing: 0;
}

label {
  font: 1rem/1.2 'Poppins', sans-serif;
  letter-spacing: 0;
}

b, strong {
  font-family: 'Poppins-Bold', sans-serif;
  font-weight: 700;
}

.text--underline {
  text-decoration: underline;
}
