.u-zoom {
  &__item {
    &--w-text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: .9125rem;
      }
      &--white {
        span {
          color: #fff;
        }
      }
    }
    &--w-icon {
      i {
        font-size: 1.2rem;
      }
    }
    input {
      &[type=range]{
        -webkit-appearance: none;
        &::-webkit-slider-runnable-track {
          height: 4px;
          background: #ddd;
          border: none;
          border-radius: 8px;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: none;
          height: 16px;
          width: 16px;
          border-radius: 100%;
          background: $brand-color-02;
          margin-top: -6px;
        }
        &:focus {
          outline: none;
          &::-webkit-slider-runnable-track {
            background: #ccc;
          }
        }
      }
    }
  }
}
