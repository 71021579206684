// Options
$font-family-base: 'Poppins', sans-serif;
$enable-rounded: true;
$enable-caret: true;
$enable-shadows: true;
$min-contrast-ratio: 3.5 !default;
$jumbotron-bg: transparent;
$dark-mode: false;

// Colors
$blue: #0071a3;
$green: #8fe85f;
$network-green: #00bd7b;
$cyan: #0091f0;
$yellow: #f9b000;
$red: #ff5349;
$orange: #f37200;
$black: #292929;
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #63666a;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$body-color: #292929;
$link-color: #0091f0;

$primary: $yellow;
$secondary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: #f5f5f5;
$dark: #212529;

// Macro
$abu-dhabi: #e4b66a;
$caraibi: #6daac9;
$grandi-crociere: #588d9c;
$mediterraneo: #008aba;
$nord-europa: #9abf62;
$oriente: #e26768;
$sud-america: #e18535;
$transatlantiche: #617a85;
$dubai: $abu-dhabi;
$oceano-indiano: #00a6fc;
$giro-del-mondo: #0a4e77;
$interna-classic: #fff9c7;
$interna-premium: #e79e44;
$interna-samsara: #8f8d8f;
$esterna-classic: #f5cce2;
$esterna-premium: #cc8caf;
$balcone-classic: #d6e4ef;
$balcone-premium: #13487f;
$balcone-samsara: #319ce3;
$suite-mini-con-balcone: #f7d0c9;
$suite-con-balcone: #c45d73;
$suite-gran-con-balcone: #af4a3f;
$suite-samsara-veranda-jacuzi: #4a7d1e;
$suite-samsara-veranda-jacuzi-balcone: #14561c;

// Costa OLD Colors
// Color Blue
$base-blue: $blue;
$light-blue: #b5d5ea;
$medium-blue: #005f89;
$dark-blue: #004c6f;
// Color Yellow
$base-yellow: $yellow;
$light-yellow: lighten($yellow, 34);
$medium-yellow: $yellow;
$base-gold: $yellow;
$medium-gold: #c49a19;
$dark-gold: adjust_hue(desaturate(darken($yellow, 23), 11), -1);
// Gray
$base-gray: $gray-600;
$gray: $gray-600;
$light-gray: #f5f5f5;
$base-gray: $gray-600;
$light-grey: $light-gray;
$dark-grey: $gray-600;
$grey-placeholder: $light-gray;
$grey: $gray;
$base-grey: $base-gray;
$medium-grey: #999;

$mercury: #e8e6e6;
$my-pink: #e08c87;
$hippie-green: #47874a;
$apple: #4db042;
$mystic: #e3edf2;
$mine-shaft: #333333;
$mahogany: $red;
$color-green1: #34403aff;
$color-green2: #04e824ff;
$color-green3: #2dffa4ff;
$color-green4: #61f6ffff;
$color-green5: #6fbeffff;
$color-green6: #2c90e8ff;

$component-active-bg: #0080d1;

// Themes
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'secondary-light': $light-blue,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark-blue': $dark-blue,
  'dark': $dark,
  'inst': 'inst',
  'abu-dhabi': 'abu-dhabi',
  'caraibi': 'caraibi',
  'grandi-crociere': 'grandi-crociere',
  'mediterraneo': 'mediterraneo',
  'nord-europa': 'nord-europa',
  'oriente': 'oriente',
  'sud-america': 'sud-america',
);

$colors: (
  'blue': $blue,
  'light-blue': $light-blue,
  'red': $red,
  'yellow': $yellow,
  'green': $green,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
);

$custom-colors: (
  'inst': #f9b000,
  'promo': #d45418,
  'abu-dhabi': #e4b66a,
  'caraibi': #6daac9,
  'grandi-crociere': #588d9c,
  'mediterraneo': #008aba,
  'nord-europa': #9abf62,
  'oriente': #e26768,
  'sud-america': #e18535,
  'interna-classic': #fff9c7,
  'interna-premium': #e79e44,
  'interna-samsara': #8f8d8f,
  'esterna-classic': #f5cce2,
  'esterna-premium': #cc8caf,
  'balcone-classic': #d6e4ef,
  'balcone-premium': #13487f,
  'balcone-samsara': #319ce3,
  'suite-mini-con-balcone': #f7d0c9,
  'suite-con-balcone': #c45d73,
  'suite-gran-con-balcone': #af4a3f,
  'suite-samsara-veranda-jacuzi': #4a7d1e,
  'suite-samsara-veranda-jacuzi-balcone': #14561c,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$body-bg: #fafafa;

// Grid
$grid-columns: 12;
$grid-gutter-width: 1.25rem;
$grid-row-columns: 6;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

// Fonts
// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: null;
$font-size-base: 0.8125rem;
$font-size-sm: $font-size-base * 0.875;
$line-height-base: 1.2;
$h1-font-size: 2em;
$h2-font-size: 1.875em;
$h3-font-size: 1.5em;
$h4-font-size: 1.154em;
$h5-font-size: 1em;
$headings-font-weight: 600;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  sm: 0.8125rem,
  xs: 0.6875rem,
  xxs: 0.625rem,
);

$font-weight-light: 100;
$close-font-weight: $font-weight-light;

// Spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 5,
  8: $spacer * 7.5,
);

// Borders
$border-color: #e0e0e0;
$hr-border-color: $border-color;
$border-radius: 0.5rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 1rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;

// Cards
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-title-spacer-y: 1rem;
$card-border-color: $border-color;
$card-bg: $white;
$card-cap-padding-y: $card-spacer-y * 0.75;
// $card-spacer-y: 0.6rem;
// $card-spacer-x: 0.6rem;

// Inputs
$input-bg: $white;
$input-btn-padding-y: 0.638rem;
$input-btn-padding-x: 0.75rem;
$input-line-height: 1.5;
$input-border-color: #a9a9a9;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

$input-placeholder-color: $gray-500;
$input-focus-width: 0;
$input-focus-border-color: $link-color;
$input-focus-box-shadow: null;
// $input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-btn-focus-width: 0.25rem;
$input-btn-focus-blur: 0;
$input-btn-focus-color-opacity: 0.75;
$input-btn-focus-color: rgba($info, $input-btn-focus-color-opacity);
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$form-check-input-width: 1.5em;

$form-label-font-size: 0.8125em;
$form-label-font-weight: 500;

$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-border-radius: 0;
$form-select-border-radius-sm: 0;
$form-select-border-radius-lg: 0;
$form-select-indicator-color: $body-color;
// $form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
$form-select-indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{str-replace(#{$form-select-indicator-color}, '#', '%23')}' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
$form-select-line-height: $input-line-height;
$form-select-bg: $white;

$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{str-replace(#{$form-feedback-icon-valid-color}, '#', '%23')}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{str-replace(#{$form-feedback-icon-invalid-color}, '#', '%23')}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{str-replace(#{$form-feedback-icon-invalid-color}, '#', '%23')}' stroke='none'/></svg>");

$form-validation-states: (
  'valid': (
    'color': $form-feedback-valid-color,
    'icon': $form-feedback-icon-valid,
  ),
  'invalid': (
    'color': $form-feedback-invalid-color,
    'icon': $form-feedback-icon-invalid,
  ),
);

// Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-divider: quote('>');

// Buttons
$btn-font-weight: 500;
$btn-padding-y: 0.638rem;
$btn-padding-x: 0.9375rem;
// $btn-padding-y-lg: .75rem;
// $btn-padding-x-lg: 4rem;
// $btn-padding-y-sm: .5rem;
// $btn-padding-x-sm: .875rem;
$btn-font-size: 0.8125rem;
$btn-font-size-sm: 0.6875rem;
$btn-font-size-lg: 0.875rem;
$btn-line-height: 1.5;
$btn-focus-width: 0.25rem;
$btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;
$btn-disabled-opacity: 0.65;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// Navbar
$navbar-light-color: $black;
$navbar-light-active-color: $black;
$navbar-nav-link-padding-x: 1rem;

// Nav Tabs
$nav-tabs-border-color: $gray-300;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-border-color: $white;
$nav-tabs-link-active-color: $medium-blue;
$nav-tabs-link-active-bg: $white;

// Nav Pills
$nav-pills-link-active-bg: $primary;
$nav-pills-link-active-color: $black;

// Toast
$toast-border-color: #e0e0e0;
$toast-background-color: $white;
$toast-header-background-color: $white;
$toast-header-border-color: transparent;

// Modals
$modal-backdrop-opacity: 0.25;
$modal-backdrop-bg: $secondary;

$modal-footer-margin-between: 0.5rem;
$modal-content-border-color: $border-color;
$modal-header-border-color: null;
$modal-header-border-width: null;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-footer-bg: null;
$modal-footer-border-color: null;
$modal-footer-border-width: null;

$modal-sm: 300px;
$modal-md: 600px;
$modal-lg: 900px;
$modal-xl: 1200px;

$modal-content-border-radius: 0;
$modal-content-bg: $white;

// Pogressbar
$progress-bar-bg: theme-color('secondary');

// Badge
$badge-font-size: 0.6875rem;
$badge-font-weight: 400;
$badge-color: $white;
$badge-padding-y: 0.5rem;
$badge-padding-x: 1rem;
$badge-border-radius: .25rem;

// Alerts
$alert-link-font-weight: 500;
$alert-border-radius: 0.25rem;
$alert-padding-y: 0.75rem;
$alert-padding-x: map-get($spacers, 3);

// Tables
$table-striped-bg-factor: 0.05;
// $table-striped-bg: rgba($blue, $table-striped-bg-factor);
$table-striped-bg: #f9f9f9;
$table-group-separator-color: $cyan;
$table-cell-padding-y: 0.5rem;
$table-cell-padding-x: 0.5rem;
$table-cell-padding-y-sm: 0.25rem;
$table-cell-padding-x-sm: 0.25rem;

// Box Shadow
$box-shadow: 1px 1px 18px rgb(0 0 0 / 17%);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);

// Dropdown
$zindex-dropdown: 1040;
$dropdown-box-shadow: 0 0 0.9375rem rgba($black, 0.1);
$dropdown-bg: $white;

// Skeleton
$placeholder-opacity-max: 0.2;
$placeholder-opacity-min: 0.1;

// Accordion
$accordion-bg: $white;
$accordion-padding-y: $spacer;
$accordion-padding-x: $spacer;
$accordion-button-padding-y: $input-padding-y;
$accordion-button-padding-x: $input-padding-x;
$accordion-button-active-color: $body-color;
// $accordion-border-width: 0;
$accordion-border-color: $card-border-color;

// Pagination
$pagination-padding-y: 0.375rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-font-size: $font-size-base;

$pagination-color: $body-color;
$pagination-bg: $white;
$pagination-border-radius: $border-radius;
$pagination-border-width: 1px;
$pagination-margin-start: ($pagination-border-width * -1);
$pagination-border-color: $body-color;

$pagination-focus-bg: $gray-200;
// $pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $body-color;

$pagination-active-color: $white;
$pagination-active-bg: $link-color;
$pagination-active-border-color: $link-color;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

$pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$pagination-border-radius-sm: $border-radius-sm;
$pagination-border-radius-lg: $border-radius-lg;

// Offcanvas
$offcanvas-horizontal-width: 600px;
$zindex-offcanvas: 1060;

$progress-border-radius: $border-radius-lg;

$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
);

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $body-color;
$tooltip-bg: $white;
$tooltip-border-radius: 0.25rem;
$tooltip-opacity: 1;
$tooltip-padding-y: $spacer * 0.5;
$tooltip-padding-x: $spacer * 0.75;
