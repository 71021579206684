@import "icons-variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8da6zd');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8da6zd#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8da6zd') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8da6zd') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8da6zd##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-inverse {
  &:before {
    content: $icon-add-inverse;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-drag-n-drop {
  &:before {
    content: $icon-drag-n-drop;
  }
}
.icon-more {
  &:before {
    content: $icon-more;
  }
}
.icon-options {
  &:before {
    content: $icon-options;
  }
}
.icon-edit-alt {
  &:before {
    content: $icon-edit-alt;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-calendar-sharp {
  &:before {
    content: $icon-calendar-sharp;
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload;
  }
}
.icon-selected-file {
  &:before {
    content: $icon-selected-file;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-background {
  &:before {
    content: $icon-background;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-editor {
  &:before {
    content: $icon-editor;
  }
}
.icon-elements {
  &:before {
    content: $icon-elements;
  }
}
.icon-feedback {
  &:before {
    content: $icon-feedback;
  }
}
.icon-finalizza {
  &:before {
    content: $icon-finalizza;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-layout {
  &:before {
    content: $icon-layout;
  }
}
.icon-light {
  &:before {
    content: $icon-light;
  }
}
.icon-search-item {
  &:before {
    content: $icon-search-item;
  }
}
.icon-shield {
  &:before {
    content: $icon-shield;
  }
}
.icon-ship-check {
  &:before {
    content: $icon-ship-check;
  }
}
.icon-ship {
  &:before {
    content: $icon-ship;
  }
}
.icon-templates {
  &:before {
    content: $icon-templates;
  }
}

.icon-save {
  &:before {
    content: $icon-save;
  }
}
