.c-tooltip {
  position: relative;
  border-bottom: 1px dotted #666;
  text-align: left;

  .top {
    min-width: 320px;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 1rem;
    color: $ui-text-dark;
    background-color: #FFF;
    font-weight: normal;
    font-size: 1rem;
    border-radius: .5rem;
    position: absolute;
    z-index: 1090;
    box-sizing: border-box;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .4);
    visibility: hidden;
    opacity: 0;
    display: none;
    transition: opacity 0.8s;

    p {
      color: $ui-text-dark;
    }
  }

  &__arrow {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
    overflow: hidden;
    cursor: default !important;

    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: #FFF;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, .4);
    }
  }

  &__options {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      cursor: pointer;
      padding: 1rem;
      &:not(:last-of-type) {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  &--popover {
    &:hover {
      .top {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &--action {
    border: 0;
    cursor: pointer;
    .open {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
}
