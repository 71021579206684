// META
$ui-background: #F8FAFB;
$ui-background-ico: #F4F4F4;
$ui-border-color: #ddd;
$ui-box-shadow: #343434;

$ui-color-01: #1867C3;
$ui-color-02: #0CA678;
$ui-color-03: #606267;
$ui-color-04: #888888;
$ui-color-05: #EBEBEB;
$ui-color-06: #F6F6F6;
$ui-color-07: #f9f9fc;
$ui-color-inverse: #fff;
$ui-color-base: #F32D7A;

$ui-text-dark: #343434;
$ui-text-placeholder: lighten(#343434, 32);
$ui-color-dark: #6C6C6C;

$text-color-primary: #343434;
$text-color-secondary: #5a6872;
$text-color-placeholder: #909090;

$color-warning: #fdd835;
$color-success: #7ed321;
$color-info: #40c4ff;
$color-danger: #ef2f4f;

$btn-bg-blue-01: #145DA4;
$btn-bg-blue-02: #0C355E;
$btn-bg-blue-03: #E7EEF5;
$btn-bg-red-01: #E11515;
$btn-bg-red-02: #C60000;
$btn-bg-red-03: #FCE7E7;
$btn-bg-dark-01: #343434;
$btn-bg-dark-02: #000;
$btn-bg-dark-03: #EBEBEB;
$btn-bg-green-01: #31b263;
$btn-bg-green-02: #066029;
$btn-bg-green-03: #B1F0C9;
$btn-bg-grey: #999BAB;

$brand-color-01: #004C6F;
$brand-color-02: #FED230;
$brand-color-03: #292929;

$color-blue: #0091F0;
$color-yellow: #F9B000;
