@import "colors";

/* _variables.scss */
$input-border-radius: 8px; /* 8px */
$card-border-radius: 8px; /* 8px */
$check-box-dimention: 20px;
$check-box-border-radius: 4px;
$header-height: 82px;
/* _variables.scss */

$page-background: #F8FAFB;
$icon-background: #F4F4F4;

/* BUTTONS */
$btn-focus-ring: 0 0 0 0.25rem;
$btn-border-width: 0.125rem;
$btn-border-radius: 2px;

$card-box-shadow: 0 6px 14px 0 rgba(31, 126, 194, 0.1);
$card-box-shadow--rise: 0 24px 36px rgba(0,0,0,0.11), 0 24px 46px rgba(0, 0, 0, 0.1);
$box-shadow-element: 0 6px 20px rgba(13, 51, 32, .1);
$card-border-color: #eee;
