/* _form-check.scss */

// FIX ME associare la classe is-invalid all'input e dare il feedback sia all'input che alla span (fatto solo per la classe invalid)
.form-check-custom {
  display: flex;
  align-items: center;
  height: $check-box-dimention;
  position: relative;
  padding-left: calc(#{$check-box-dimention} + 0.625rem);
  margin-bottom: 1rem;
  cursor: pointer;
  font-size: 0.88rem;
  user-select: none;

  input {
    &.invalid,
    &.is-invalid {
      ~ .form-check-custom {
        &__check,
        &__radio {
          color: $color-danger;
        }

        &__label {
          color: $color-danger;
        }
      }
    }
  }

  &.disabled,
  &.is-disabled {
    opacity: 0.8;
    pointer-events: none;
    .form-check-custom {
      &__check,
      &__radio {
        opacity: 0.8;
        pointer-events: none;
        background-color: transparent !important;
        border-color: $ui-color-04 !important;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $btn-bg-green-02;
            &:after {
              border-color: $btn-bg-green-02;
            }
          }
          &__radio {
            &:after {
              background-color: $brand-color-01;
            }
          }
        }
      }
    }
  }

  &.invalid,
  &.is-invalid {
    color: $color-danger;
    .form-check-custom {
      &__check,
      &__radio {
        border-color: $color-danger;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $color-danger;
            &:after {
              border-color: $color-danger;
            }
          }
          &__radio {
            &:after {
              background-color: $color-danger;
            }
          }
        }
      }
    }
  }

  &.valid,
  &.is-valid {
    color: $color-success;
    .form-check-custom {
      &__check,
      &__radio {
        border-color: $color-success;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $color-success;
            &:after {
              border-color: $color-success;
            }
          }
          &__radio {
            &:after {
              background-color: $color-success;
            }
          }
        }
      }
    }
  }

  &.warning,
  &.is-warning {
    color: $color-warning;
    .form-check-custom {
      &__check,
      &__radio {
        border-color: $color-warning;
      }
    }
    input {
      &:checked {
        ~ .form-check-custom {
          &__check,
          &__radio {
            border-color: $color-warning;
            &:after {
              border-color: $color-warning;
            }
          }
          &__radio {
            &:after {
              background-color: $color-warning;
            }
          }
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ .form-check-custom {
        &__check,
        &__radio {
          background-color: #fff;
          border: 1px solid #8993A9;
          &:after {
            display: block;
          }
        }
      }
    }
  }

  &__check {
    position: absolute;
    top: 0;
    left: 0;
    height: $check-box-dimention;
    width: $check-box-dimention;
    background-color: #fff;
    border: 1px solid #bfbfbf;
    border-radius: calc(#{$input-border-radius} / 2);
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 32%;
      top: 5%;
      width: calc(#{$check-box-dimention} / 3);
      height: calc(#{$check-box-dimention} / 1.5);
      border: solid $brand-color-01;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &__radio {
    position: absolute;
    top: 0;
    left: 0;
    height: $check-box-dimention;
    width: $check-box-dimention;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid $btn-bg-green-02;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 14%;
      left: 14%;
      width: calc(#{$check-box-dimention} / 1.5);
      height: calc(#{$check-box-dimention} / 1.5);
      border-radius: calc(#{$check-box-dimention} / 1.5);
      background: $color-success;
    }
  }

  &__label {
    position: relative;
    margin-right: 1rem;
  }
  &__logo {
    position: absolute;
    right: .5rem;
    width: 2rem;
  }
}

.form-check-big {
  height: auto;
  padding: 0;
  &__label {
    font-size: 1.375rem;
    color: $brand-color-03;
    font-family: 'Poppins-Bold', sans-serif;
    background-color: #EBF9FF;
    border-radius: 5px;
    padding: 20px 60px 20px;
    position: relative;
    cursor: pointer;
    width: 100%;
  }
  &__price {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    font-size: 1.5rem;
    color: $brand-color-03;
    font-family: 'Poppins-Bold', sans-serif;
    span {
      font-size: 1rem;
      color: #000;
      font-family: 'Poppins', sans-serif;
    }
  }
  &__caption {
    font-size: .875rem;
    line-height: 1.7;
    color: #000;
  }
  &__radio {
    top: 1.5rem;
    left: 1.5rem;
  }
}
/* _form-check.scss END */
