.u-toggle {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    &--w-text {
      &--white {
        span {
          color: #fff;
        }
      }
    }
    &--max-width {
      width: 300px;
    }
    &--bb {
      border-bottom: 1px solid #A9A9A9;
    }
    span {
      font-size: .8125rem;
    }
  }
  &__switch {
    display: inline-block;
    height: 29px;
    position: relative;
    width: 58px;
    input {
      display:none;
    }
  }
  &__slider {
    background-color: #fff;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    border: 1px solid #ccc;
    &:after {
      content: 'o';
      position: absolute;
      top: 3px;
      right: 12px;
    }
    &:before {
      background-color: #fff;
      bottom: 1px;
      content: "";
      height: 23px;
      left: 1px;
      position: absolute;
      transition: 0.4s;
      border: 1px solid #ccc;
      width: 23px;
      z-index: 2;
    }
    &.round {
      border-radius: 100px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  input {
    &:checked + .u-toggle__slider {
      background-color: #F9B000;
      border-color: #F9B000;
      &:before {
        transform: translateX(29px);
        border-color: transparent;
      }
      &:after {
        content: 'l';
        position: absolute;
        top: 6px;
        left: 18px;
        font-size: .875rem;
      }
    }
  }
}
