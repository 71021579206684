@import "variables";

.c-button {
  font-family: 'Poppins-Medium', sans-serif;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.3;
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  position: relative;
  box-sizing: border-box;
  float: none;
  padding: .75rem 1.2rem;
  z-index: 0;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow .22s ease-out, background-color .22s ease-out, color .22s ease-in;
  user-select: none;
  border-radius: $btn-border-radius;

  &.disabled,
  &.is-disabled,
  &[disabled] {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

  &.t-primary {
    color: #FFF;
    background-color: $brand-color-01;
    border: 1px solid $brand-color-01;

    &:focus,
    &:hover {
      color: #FFF;
      background-color: darken($brand-color-01, 7);
      border-color: darken($brand-color-01, 7);
      transition: background-color .11s ease-in, color .22s ease-in;
      outline: none;
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }

  }

  &.t-secondary {
    color: $brand-color-03;
    background-color: $brand-color-02;
    border: $btn-border-width solid $brand-color-02;

    &:focus,
    &:hover {
      color: $brand-color-03;
      background-color: darken($brand-color-02, 7);
      border-color: darken($brand-color-02, 7);
      transition: background-color .11s ease-in, color .22s ease-in;
      outline: none;
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }

  }

  &.t-ghost {
    color: $brand-color-03;
    background-color: transparent;
    border: $btn-border-width solid $brand-color-03;

    &:focus,
    &:hover {
      color: darken($brand-color-03, 7);
      border-color: darken($brand-color-03, 7);
      transition: border-color .11s ease-in, color .22s ease-in;
      outline: none;
    }

    &:active {
      text-decoration: none;
    }

  }

  &--bold {
    font-family: 'Poppins-Bold', sans-serif;
  }

  &--xs {
    padding: .35rem .6rem;
  }

  &--sm {
    padding: 0.5rem 1rem;
  }

  &--lg {
    padding: 1.125rem 3.750rem;
  }

  &--wide {
    padding: .75rem 2rem;
  }

  &--wider {
    padding: .75rem 4rem;
  }

  &--block {
    width: 100%;
  }

  &--w-fit-content {
    width: fit-content;
  }

  &--w-icon {
    i {
      display: inline-block;
      margin-right: .5rem;
    }
  }

  &--font-md {
    font-size: .875rem;
  }

  &--font-sm {
    font-size: .8125rem;
  }

  &--font-xs {
    font-size: .685rem;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    font-size: 1rem;
    margin-left: .5rem;
  }

  &--font-primary {
    font-family: "Poppins", sans-serif;
  }

  &__wrapper {
    button {
      + button {
        margin-left: .5rem;
      }
    }
  }

}
