

// 5. Note
// Importa il font su html e modifica font su file _variables.scss;




/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import '../node_modules/swiper/swiper';
@import "global/styles/smart-modal";
@import "global/styles/typography";
@import "global/styles/colors";
@import "global/styles/common";
@import "global/styles/c-buttons";
@import "global/styles/forms";
@import "global/styles/u-zoom";
@import "global/styles/errors";
@import "global/styles/u-toggle";
@import "global/styles/u-list";
@import "global/styles/mixin/ellipsis";
@import "global/styles/icons";
@import "global/styles/c-tooltip";


// 1. Importa le tua variabili
@import 'styles/variables';

// 2. Importa le utlities modificate
@import 'styles/utilities';

// 3. Importa Bootstrap
/* stylelint-disable */
@import '../node_modules/bootstrap/scss/bootstrap';
/* stylelint-enable */

// 4. CSS Personalizzato
@import 'styles/custom';

// 5. Bootstrap Icons
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

swiper-container {
  --swiper-navigation-size: 1rem;
  --swiper-theme-color: #0091F0;
}

swiper-container::part(button-prev), swiper-container::part(button-next) {
  --btn-l: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--btn-l);
  width: var(--btn-l);
  background-color: rgba(255, 255, 255, 0.65);
  padding: .5rem;
  border-radius: var(--btn-l);
  will-change: background-color;
  transition: background-color .33s ease-out;
  top: calc(50% - calc(var(--btn-l) / 2));

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color .22s ease-in;
  }
}

.button-delete {
  --btn-l: 2rem;
  position: absolute;
  right: calc(calc(var(--btn-l) / 2) * -1);
  display: flex;
  color: #0091F0;
  align-items: center;
  justify-content: center;
  height: var(--btn-l);
  width: var(--btn-l);
  background-color: rgba(255, 255, 255, 0.65);
  padding: .5rem;
  border-radius: var(--btn-l);
  will-change: background-color;
  transition: background-color .33s ease-out;
  top: calc(50% - calc(var(--btn-l) / 2));
  border: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color .22s ease-in;
  }
}

.position-relative {
  position: relative;
}

html {
  height: 100%;
  body {
    height: 100%;
    mkb-root {
      > div {
        height: 100%;
      }
    }
  }
}

// Sticky footer styles
html, body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Main content container should grow to push footer down
.main-content {
  flex: 1 0 auto;
}

.editable[contenteditable=true] {
  outline: 2px dashed #B1338E;
  &.o-hgroup__title {
    outline: none;
  }
}

.editable-outline {
  outline: 2px dashed #B1338E;
  [contenteditable=true] {
    outline: none;
  }
}

.nsm-dialog.nsm-dialog-animation-ttb {
  max-width: 1198px !important;
  width: 80%;
}

::ng-deep {
  ng-select {
    background-color: #fff;
    height: 36px;
    border-color: #A9A9A9;
    cursor: pointer;
    &.ng-select-disabled {
      background-color: #cccccc;
    }
    .ng-value-container {
      padding: 0;
      .ng-value {
        align-self: center;
      }
    }
    .ng-select-container {
      height: 100%;
      cursor: pointer;
      padding: .35rem .75rem;
    }
    .ng-dropdown-panel {
      background-color: #fff;
      margin-top: 2px;
      padding: .5rem 1rem;
      .ng-option {
        padding: .35rem 0;
      }
    }
    .ng-has-value .ng-placeholder{
      display: none;
    }

    .ng-placeholder {
      color: #A9A9A9;
    }
  }
}

.transparent-content * {
  opacity: 0.9;
  pointer-events: none;
  user-select: none;
  cursor: grab;
}

.sortable-chosen {
  &.sortable-fallback {
    opacity: 1 !important;
    .c-tab-accordion__label {
      box-shadow: 0 0 15px 0 rgb(0 0 0 / 40%), inset 4px 0 $brand-color-02 !important;
    }

  }
  &:not(.sortable-fallback) {
    opacity: 0 !important;
  }
}

// .sortable-fallback {
//   // opacity: 0 !important;
// }

.w {
  &-full {
    width: 100%;
  }

  &-33 {
    width: 33.33%;
  }
}

.h-full {
  height: 100%;
}

.mt {
  &-1 {
    margin-top: 0.25rem;
  }
  &-2 {
    margin-top: 0.5rem;
  }
  &-3 {
    margin-top: 0.75rem;
  }
  &-4 {
    margin-top: 1rem;
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -.125em;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  border: .25em solid currentcolor;
  border-right-color: transparent;
}

// scss-docs-start spinner-border-keyframes
@keyframes spinner-border {
  to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}
